import { isMenuItemBerry } from '../../types/configuration';
import MenuItemContentBerry from './MenuItemContentBerry';
import MenuItemContentGrow from './MenuItemContentGrow';
import MenuItemContentHome from './MenuItemContentHome';
import MenuItemContentHub from './MenuItemContentHub';
import MenuItemContentSweetest from './MenuItemContentSweetest';

import { MenuItemContentBaseProps } from './types/items';

interface MenuItemContentProps extends MenuItemContentBaseProps {}

export default function MenuItemContent({
  config,
  ...props
}: MenuItemContentProps) {
  if (isMenuItemBerry(config)) {
    return (
      <MenuItemContentBerry
        config={config}
        {...props}
      />
    );
  }

  switch (config.type) {
    case 'hub':
      return (
        <MenuItemContentHub
          config={config}
          {...props}
        />
      );
    case 'sweetest':
      return (
        <MenuItemContentSweetest
          config={config}
          {...props}
        />
      );
    case 'grow':
      return (
        <MenuItemContentGrow
          config={config}
          {...props}
        />
      );
    case 'home':
      return (
        <MenuItemContentHome
          config={config}
          {...props}
        />
      );
  }

  return null;
}
