import {
  useRef,
} from 'react';

import type { MenuItemContentBaseProps } from './types/items';
import ExploreArrow from './svg/ExploreArrow';
import { usePreventDrag } from './hooks/useDragRotation';

const buttonStyle: React.CSSProperties = {
  background: 'none',
  border: 'none',
  width: 'min(20vw, 20vh, 100px)',
  cursor: 'pointer',
};

interface MenuItemExploreProps extends Partial<Pick<MenuItemContentBaseProps, 'config'>> {
  onClick?: () => any;
  enabled?: boolean;
}

export default function MenuItemExplore({
  config,
  onClick,
  enabled = false,
}: MenuItemExploreProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  usePreventDrag(buttonRef);

  const {
    route = undefined,
    exploreColor = enabled ? 'white' : 'transparent',
  } = config || {};

  const transition = enabled ? 'color .9s' : 'color 0.3s';
  const pointerEvents = enabled ? 'auto' : 'none';

  const clickAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (route) {
      onClick?.();

      // @todo Update if / as required for WordPress build
      window.history.pushState({}, '', route);
    }
  };

  return (
    <button
      ref={buttonRef}
      onClick={clickAction}
      style={{
        ...buttonStyle,
        transition,
        pointerEvents,
        color: exploreColor,
      }}
    >
      <ExploreArrow
        width="100%"
        height="100%"
      />
    </button>
  );
}
