import { useId } from 'react';

import type { MenuItemContentBaseProps } from './types/items';
import type { WidthHeight } from './types/points';
import MenuSlice from './MenuSlice';
import MenuItemContent from './MenuItemContent';

interface MenuItemProps extends MenuItemContentBaseProps, WidthHeight {
  /** "Width" of slice's sector */
  sector?: number;
  /** Dividing line thickness */
  strokeWidth?: number;
}

export default function MenuItem({
  config,
  state,
  rotation,
  width,
  height,
  strokeWidth = 8,
  sector = Math.PI / 4,
}: MenuItemProps) {
  const clipId = useId();
  const {
    background,
  } = config;

  const menuSlice = (
    <MenuSlice
      fill={background}
      strokeWidth={strokeWidth}
      rotation={rotation}
      sector={sector}
      width={width}
      height={height}
    />
  );

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMax meet"
    >
      <defs>
        <clipPath id={clipId}>
          {menuSlice}
        </clipPath>
      </defs>
      {menuSlice}
      <g clipPath={`url(#${clipId})`}>
        <MenuItemContent
          config={config}
          rotation={rotation}
          state={state}
          width={width}
          height={height}
        />
      </g>
      <MenuSlice
        stroke="white"
        strokeWidth={strokeWidth}
        rotation={rotation}
        sector={sector}
        width={width}
        height={height}
      />
    </svg>
  );
}
