import type React from 'react';
import {
  useSpring,
  animated,
  to,
} from '@react-spring/web';
import type { WidthHeight } from './types/points';

const ROTATION_OFFSET = Math.PI / -2;

const polygonStyle: React.SVGAttributes<SVGPolygonElement>['style'] = {
  vectorEffect: 'non-scaling-stroke',
};

interface MenuSliceProps extends WidthHeight {
  /** Center rotation of slice */
  rotation: number;
  /** "Width" of slice's sector */
  sector: number;
  /** Primary slice colour */
  fill?: string;
  /** Stroke colour */
  stroke?: string;
  /** Stroke width, noting that it affects dimensions even when no stroke is used */
  strokeWidth?: number;
}

export default function MenuSlice({
  rotation,
  sector,
  width,
  height,
  fill,
  stroke,
  strokeWidth = 0,
}: MenuSliceProps) {
  const spring = useSpring({
    to: {
      rotation,
      sector,
    },
  });

  if (!fill && !stroke) {
    return null;
  }

  const hypotenuse = Math.sqrt(width ** 2 + height ** 2) * 2;
  const adjustedHeight = height + strokeWidth / 2

  return (
    <animated.polygon
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      style={polygonStyle}
      points={to(
        [spring.rotation, spring.sector],
        (rotation, sector) => {
          const angleA = rotation + ROTATION_OFFSET - sector / 2;
          const angleB = rotation + ROTATION_OFFSET + sector / 2;

          if (fill) {
            return (`
              ${width / 2}, ${adjustedHeight}
              ${width / 2 + Math.cos(angleA) * hypotenuse}, ${adjustedHeight + Math.sin(angleA) * hypotenuse}
              ${
                /**
                 * Note that this is a bit of a hack and won't work if the sector is more than 180°
                 * or if the sector is more than 90° and the rotation is more than 90°
                 *
                 * Given more time, we'd probably want to calculate each point based on the sector
                 * and rotation, but this is a good enough approximation for now.
                 */
                sector > Math.PI / 2
                ? `${-width},0 ${width * 2},0`
                : ''
              }
              ${width / 2 + Math.cos(angleB) * hypotenuse}, ${adjustedHeight + Math.sin(angleB) * hypotenuse}
            `);
          }

          // i.e. Draw only the stroke, returning through the origin
          return (`
            ${width / 2}, ${adjustedHeight}
            ${width / 2 + Math.cos(angleA) * hypotenuse}, ${adjustedHeight + Math.sin(angleA) * hypotenuse}
            ${width / 2}, ${adjustedHeight}
            ${width / 2 + Math.cos(angleB) * hypotenuse}, ${adjustedHeight + Math.sin(angleB) * hypotenuse}
          `);
        },
      )}
    />
  );
}
