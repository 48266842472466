import type React from 'react';
import type { MenuItemBerry } from '../../types/configuration';
import {
  animated,
  to,
  useSpring,
} from '@react-spring/web';
import { MenuItemContentBaseProps } from './types/items';
import MenuItemContentBerryTitle from './MenuItemContentBerryTitle';
import { image } from './helpers/image';

const hardwareHackStyle: React.CSSProperties = {
  // backfaceVisibility: 'hidden',
};

interface MenuItemContentBerryProps extends MenuItemContentBaseProps<MenuItemBerry> {}

interface MenuItemStateProps {
  berryScale: number;
  titleOpacity: number;
  /** This is used to make modifications in open state only */
  open: number;
}

function stateProps(state: string): MenuItemStateProps {
  switch (state) {
    case 'active':
      return {
        berryScale: .888,
        titleOpacity: 1,
        open: 0,
      };
    case 'open':
      return {
        berryScale: .666,
        titleOpacity: 0,
        open: 1,
      };
    case 'inactive':
    default:
      return {
        berryScale: 1.222,
        titleOpacity: 0,
        open: 0,
      };
  }
}

export default function MenuItemContentBerry({
  config,
  rotation,
  width,
  height,
  state = 'inactive',
}: MenuItemContentBerryProps) {
  const {
    berryScale,
    titleOpacity,
    open,
  } = stateProps(state);
  const {
    type,
    berry,
  } = config;
  const wheelSpring = useSpring({
    to: {
      rotation,
    },
    config: {
      tension: 170 - 8,
      friction: 26 + 3,
    },
  });
  const menuStateSpring = useSpring({
    to: {
      berryScale,
      open,
      titleOpacity,
    },
    config: {
      tension: 170 - 4,
      friction: 26 + 1,
    },
  });
  const responsiveBerry = Math.max(1, (height / width) **.666);
  const responsiveTitle = Math.max(1, (height / width) **.222);

  return (
    <animated.svg
      viewBox={to(
        menuStateSpring.open,
        (open) => `0 0 ${100 + open * 50} 100`
      )}
      preserveAspectRatio="xMidYMid meet"
    >
      <animated.g
        will-change="style"
        transform="
          translate(0 -6)
        "
        style={{
          color: 'white',
          transformOrigin: '50% 50%',
          opacity: to(
            [
              menuStateSpring.titleOpacity,
              menuStateSpring.open,
            ],
            (titleOpacity, open) => (open * .666) - (titleOpacity * open)**1.3,
          ),
        }}
      >
        <MenuItemContentBerryTitle
          type={type}
          y="-4"
        />
      </animated.g>
      <animated.image
        will-change="transform"
        style={hardwareHackStyle}
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.berryScale,
            menuStateSpring.open,
          ],
          (rotation, berryScale, open) => {
            const translation = (1 - berryScale * responsiveBerry) * (50 + open * 25);

            return `
              translate(${translation} ${translation / 3})
              rotate(${(rotation) * 180 / Math.PI} 50 100)
              scale(${berryScale * responsiveBerry})
            `;
          },
        )}
        href={image(berry)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
      <animated.g
        will-change="style"
        transform={`
          translate(0 -6)
          scale(${.666 * responsiveTitle})
        `}
        style={{
          color: 'white',
          transformOrigin: '50% 50%',
          filter: 'drop-shadow(0 0 12px rgba(0, 0, 0, .8))',
          opacity: to(
            menuStateSpring.titleOpacity,
            (titleOpacity) => titleOpacity**1.3,
          ),
        }}
      >
        <MenuItemContentBerryTitle
          type={type}
          y="-4"
        />
      </animated.g>
    </animated.svg>
  );
}
