import type React from 'react';
import {
  type MenuItemSweetest,
} from '../../types/configuration';
import {
  animated,
  to,
  useSpring,
} from '@react-spring/web';
import { MenuItemContentBaseProps } from './types/items';
import TitleSweetestBatch from './svg/TitleSweetestBatch';
import { image } from './helpers/image';
import { useId } from 'react';

interface MenuItemContentSweetestProps extends MenuItemContentBaseProps<MenuItemSweetest> {}

interface MenuItemStateProps {
  titleOpacity: number;
  titleScale: number;
  berryScale: number;
  berryARotation: number;
  berryBRotation: number;
  leafScale: number;
  leafARotation: number;
  leafBRotation: number;
}

function stateProps(state: string): MenuItemStateProps {
  switch (state) {
    case 'active':
      return {
        titleOpacity: 1,
        titleScale: .444,
        berryScale: .4,
        berryARotation: -Math.PI / 33,
        berryBRotation: Math.PI / 15,
        leafScale: .555,
        leafARotation: Math.PI / 16,
        leafBRotation: Math.PI / 5,
      };
    case 'open':
      return {
        titleOpacity: 1,
        titleScale: .444,
        berryScale: .422,
        berryARotation: -Math.PI / 40,
        berryBRotation: Math.PI / 18,
        leafScale: .577,
        leafARotation: Math.PI / 14,
        leafBRotation: Math.PI / 6,
      };
    case 'inactive':
    default:
      return {
        titleOpacity: 0,
        titleScale: 0,
        berryScale: .888,
        berryARotation: Math.PI / 18,
        berryBRotation: -Math.PI / 20,
        leafScale: 1,
        leafARotation: Math.PI / 40,
        leafBRotation: Math.PI / 3,
      };
  }
}

export default function MenuItemContentSweetest({
  config: {
    leafs: [
      leafA,
      leafB,
    ],
    berries: [
      berryA,
      berryB,
    ],
  },
  rotation,
  state = 'inactive',
}: MenuItemContentSweetestProps) {
  const titleGradientId = useId();

  const {
    titleScale,
    titleOpacity,
    berryScale,
    berryARotation,
    berryBRotation,
    leafScale,
    leafARotation,
    leafBRotation,
  } = stateProps(state);

  const wheelSpring = useSpring({
    to: {
      rotation,
    },
    config: {
      tension: 170 - 8,
      friction: 26 + 3,
    },
  });
  const menuStateSpring = useSpring({
    to: {
      titleOpacity,
      titleScale,
      berryScale,
      berryARotation,
      berryBRotation,
      leafScale,
      leafARotation,
      leafBRotation,
    },
    config: {
      tension: 170 - 4,
      friction: 26 + 1,
    },
  });

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <linearGradient
          id={titleGradientId}
          x1="0"
          x2="0"
          y1="0"
          y2="1"
        >
          <stop offset="5%" stopColor="#f9f09b" />
          <stop offset="95%" stopColor="#b39a54" />
        </linearGradient>
      </defs>
      <animated.g
        will-change="style"
        transform="
          translate(0 -6)
          scale(.666)
        "
        style={{
          color: `#e8e18c`,
          transformOrigin: '50% 50%',
          opacity: to(
            menuStateSpring.titleOpacity,
            (titleOpacity) => titleOpacity,
          ),
        }}
      >
        <TitleSweetestBatch
          y="-16"
          textFill={`url(#${titleGradientId})`}
        />
      </animated.g>
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.leafScale,
            menuStateSpring.leafBRotation,
          ],
          (
            wheelRotation,
            leafScale,
            leafRotation,
          ) => {
            const translation = (1 - leafScale) * 50;

            return `
              translate(${translation * .3 - 45} ${translation * 3.33 - 30})
              rotate(${(wheelRotation + leafRotation * .88) * 180 / Math.PI} 50 100)
              scale(${leafScale})
            `;
          },
        )}
        href={image(leafB)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.berryScale,
            menuStateSpring.berryBRotation,
          ],
          (
            wheelRotation,
            berryScale,
            berryRotation,
          ) => {
            const translation = (1 - berryScale) * 50;

            return `
              translate(${translation * .6 - 20} ${translation * 2.2 - 10})
              rotate(${(wheelRotation + berryRotation) * 180 / Math.PI} 50 100)
              scale(${berryScale * 1.1})
            `;
          },
        )}
        href={image(berryB)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.leafScale,
            menuStateSpring.leafARotation,
          ],
          (
            wheelRotation,
            leafScale,
            leafRotation,
          ) => {
            const translation = (1 - leafScale) * 50;

            return `
              translate(${translation * 0.3 + 20} ${translation * 2.6 - 20})
              rotate(${(wheelRotation + leafRotation) * 180 / Math.PI} 50 100)
              scale(${leafScale})
            `;
          },
        )}
        href={image(leafA)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
      <animated.image
        will-change="transform"
        transform={to(
          [
            wheelSpring.rotation,
            menuStateSpring.berryScale,
            menuStateSpring.berryARotation,
          ],
          (
            wheelRotation,
            berryScale,
            berryRotation,
          ) => {
            const translation = (1 - berryScale) * 50;

            return `
              translate(${translation * 1.1 + 10} ${translation * 1.8})
              rotate(${(wheelRotation + berryRotation) * 180 / Math.PI} 50 100)
              scale(${berryScale})
            `;
          },
        )}
        href={image(berryA)}
        width="100%"
        height="100%"
        y="0"
        x="0"
      />
    </svg>
  );
}
